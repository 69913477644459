.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--z-index-header);
  background-color: var(--plt-neutral-900);
  color: white;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--sp-4);
  border-bottom: 1px solid var(--plt-neutral-400);
  /* box-shadow: var(--box-shadow-0); */
  height: var(--header-height);
}

.link {
  background: none;
  text-decoration: none;
  color: inherit;
  font-weight: 700;
}

.logo {
  width: 26px;
}

.menu_button {
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid transparent;
  outline: none;
  border-radius: var(--br-3);
  cursor: pointer;
  padding: 0;
}

.menu_button:focus,
.menu_button:active {
  border-color: var(--plt-neutral-200);
}

.menu_button svg {
  width: 26px;
  height: 26px;
  fill: white;
}

.menu_list {
  left: 0;
  top: var(--header-height);
  width: 100vw;
  /* height: calc(100vh - var(--header-height)); */
  border: none;
  background-color: white;
  position: fixed;
  padding: var(--sp-2) 0;
  font: inherit;
  box-shadow: var(--box-shadow-1);
  z-index: var(--z-index-header-menu);
}

.menu_item {
  padding: var(--sp-2) var(--sp-4);
  color: var(--plt-neutral-900);
  font-size: var(--fs-3);
  outline: none;
}

.menu_item:not(:last-of-type) {
  border-bottom: 1px solid var(--plt-neutral-100);
}

.menu_item[data-selected] {
  background-color: var(--plt-neutral-000);
  color: var(--plt-neutral-900);
}

.sign_out_text,
.sign_out_text[data-selected] {
  color: var(--plt-danger-400);
}

@media (min-width: 768px) {
  .menu_list {
    width: initial;
    left: initial;
    right: var(--sp-4);
  }
}
