.root {
  margin: 20vh var(--sp-4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: var(--fs-9);
  color: black;
  font-weight: 700;
  margin-block-end: var(--sp-3);
}

.sub_heading {
  font-size: var(--fs-4);
  color: var(--plt-neutral-600);
}

@media (min-width: 768px) {
  .root {
    margin: 20vh auto;
  }
}
