:root {
  /* --ff-0: "Source Sans Pro", sans-serif; */
  /* --ff-0: "Lato", sans-serif; */
  --ff-0: "Inter", sans-serif;
  /* --ff-1: "Roboto Slab", serif; */

  /* type scale */
  /* p91 */
  --fs-0: 12px;
  --fs-1: 13px;
  --fs-2: 14px;
  --fs-3: 15px;
  --fs-4: 17px;
  --fs-5: 20px;
  --fs-6: 24px;
  --fs-7: 30px;
  --fs-8: 36px;
  --fs-9: 48px;
  --fs-A: 60px;
  --fs-B: 72px;

  /* spacing */
  --sp-0: 2px;
  --sp-1: 4px;
  --sp-2: 8px;
  --sp-3: 12px;
  --sp-4: 16px;
  --sp-5: 24px;
  --sp-6: 32px;
  --sp-7: 48px;
  --sp-8: 64px;
  --sp-9: 96px;
  --sp-A: 128px;
  --sp-B: 256px;
  --sp-C: 384px;
  --sp-D: 512px;
  --sp-E: 640px;
  --sp-F: 768px;

  /* border-radius */
  --br-0: 1px;
  --br-1: 2px;
  --br-2: 4px;
  --br-3: 8px;
  --br-4: 12px;
  --br-5: 16px;

  /* box-shadow */
  --box-shadow-0: 0 2px 10px var(--plt-neutral-200);
  --box-shadow-1: 0 2px 10px var(--plt-neutral-300);

  --z-index-sidebar: 2;
  --z-index-sidebar-menu: 3;
  --z-index-header-menu: 4;
  --z-index-header: 5;
  --z-index-tooltip: 6;
  --z-index-floating-action-button: 7;
  --z-index-dialog-overlay: 8;
  --z-index-dialog-content: 9;
  --z-index-toast: 10;

  --input-height: 34px;
  --input-sm-height: 28px;

  --header-height: 36px;
  --page-header-height: 40px;
  --sidebar-width: 200px;
  --details-sidebar-width: 255px;
}

body {
  font-family: var(--ff-0);
  font-size: var(--fs-2);
  font-display: optional;
  line-height: 1.4;
  color: var(--plt-neutral-800);
  /* https://stackoverflow.com/a/11885330 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* ::selection {
  background-color: var(--plt-supporting-2-000);
} */

::placeholder {
  color: var(--plt-neutral-500);
}

.BaseTable {
  box-shadow: none;
}

.BaseTable__table-main {
  outline: none;
}

.BaseTable__header-row,
.BaseTable__row {
  border-bottom: 1px solid var(--plt-neutral-200);
}

.BaseTable__row,
.BaseTable__row:hover,
.BaseTable__row--hovered {
  background-color: white;
}

.BaseTable__header-row,
.BaseTable__header-cell,
.emphasis_row.BaseTable__row {
  background-color: hsl(194, 54%, 97%);
}

.BaseTable__header-cell,
.BaseTable__row-cell {
  border-right: 1px solid var(--plt-neutral-200);
}

.empty_row.BaseTable__row .BaseTable__row-cell,
.emphasis_row.BaseTable__row .BaseTable__row-cell {
  border-right: none;
}

.BaseTable__table-frozen-right,
.BaseTable__table-frozen-left {
  box-shadow: none;
}
