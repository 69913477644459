:root {
  --plt-success-000: hsl(125, 65%, 93%);
  --plt-success-100: hsl(127, 65%, 85%);
  --plt-success-200: hsl(124, 63%, 74%);
  --plt-success-300: hsl(123, 53%, 55%);
  --plt-success-400: hsl(123, 57%, 45%);
  --plt-success-500: hsl(122, 73%, 35%);
  --plt-success-600: hsl(122, 80%, 29%);
  --plt-success-700: hsl(125, 79%, 26%);
  --plt-success-800: hsl(125, 86%, 20%);
  --plt-success-900: hsl(125, 97%, 14%);

  --plt-danger-000: hsl(0, 100%, 95%);
  --plt-danger-100: hsl(0, 100%, 87%);
  --plt-danger-200: hsl(0, 100%, 80%);
  --plt-danger-300: hsl(0, 91%, 69%);
  --plt-danger-400: hsl(0, 83%, 62%);
  --plt-danger-500: hsl(356, 75%, 53%);
  --plt-danger-600: hsl(354, 85%, 44%);
  --plt-danger-700: hsl(352, 90%, 35%);
  --plt-danger-800: hsl(350, 94%, 28%);
  --plt-danger-900: hsl(348, 94%, 20%);

  --plt-info-000: hsl(45, 100%, 96%);
  --plt-info-100: hsl(45, 90%, 88%);
  --plt-info-200: hsl(45, 86%, 81%);
  --plt-info-300: hsl(43, 90%, 76%);
  --plt-info-400: hsl(43, 89%, 70%);
  --plt-info-500: hsl(42, 78%, 60%);
  --plt-info-600: hsl(42, 63%, 48%);
  --plt-info-700: hsl(43, 72%, 37%);
  --plt-info-800: hsl(43, 77%, 27%);
  --plt-info-900: hsl(43, 86%, 17%);
}
