:root {
  --plt-primary-000: hsl(221, 68%, 93%);
  --plt-primary-100: hsl(221, 78%, 86%);
  --plt-primary-200: hsl(224, 67%, 76%);
  --plt-primary-300: hsl(225, 57%, 67%);
  --plt-primary-400: hsl(227, 50%, 59%);
  --plt-primary-500: hsl(227, 42%, 51%);
  --plt-primary-600: hsl(228, 45%, 45%);
  --plt-primary-700: hsl(230, 49%, 41%);
  --plt-primary-800: hsl(232, 51%, 36%);
  --plt-primary-900: hsl(234, 62%, 26%);

  --plt-secondary-000: hsl(171, 82%, 94%);
  --plt-secondary-100: hsl(172, 97%, 88%);
  --plt-secondary-200: hsl(174, 96%, 78%);
  --plt-secondary-300: hsl(176, 87%, 67%);
  --plt-secondary-400: hsl(178, 78%, 57%);
  --plt-secondary-500: hsl(180, 77%, 47%);
  --plt-secondary-600: hsl(182, 85%, 39%);
  --plt-secondary-700: hsl(184, 90%, 34%);
  --plt-secondary-800: hsl(186, 91%, 29%);
  --plt-secondary-900: hsl(188, 91%, 23%);

  --plt-neutral-000: hsl(216, 33%, 97%);
  --plt-neutral-100: hsl(214, 15%, 91%);
  --plt-neutral-200: hsl(210, 16%, 82%);
  --plt-neutral-300: hsl(211, 13%, 65%);
  --plt-neutral-400: hsl(211, 10%, 53%);
  --plt-neutral-500: hsl(211, 12%, 43%);
  --plt-neutral-600: hsl(209, 14%, 37%);
  --plt-neutral-700: hsl(209, 18%, 30%);
  --plt-neutral-800: hsl(209, 20%, 25%);
  --plt-neutral-900: hsl(210, 24%, 16%);

  --plt-supporting-0-000: hsl(341, 100%, 95%);
  --plt-supporting-0-100: hsl(338, 100%, 86%);
  --plt-supporting-0-200: hsl(336, 100%, 77%);
  --plt-supporting-0-300: hsl(334, 86%, 67%);
  --plt-supporting-0-400: hsl(330, 79%, 56%);
  --plt-supporting-0-500: hsl(328, 85%, 46%);
  --plt-supporting-0-600: hsl(326, 90%, 39%);
  --plt-supporting-0-700: hsl(324, 93%, 33%);
  --plt-supporting-0-800: hsl(322, 93%, 27%);
  --plt-supporting-0-900: hsl(320, 100%, 19%);

  --plt-supporting-1-000: hsl(0, 100%, 95%);
  --plt-supporting-1-100: hsl(0, 100%, 87%);
  --plt-supporting-1-200: hsl(0, 100%, 80%);
  --plt-supporting-1-300: hsl(0, 91%, 69%);
  --plt-supporting-1-400: hsl(0, 83%, 62%);
  --plt-supporting-1-500: hsl(356, 75%, 53%);
  --plt-supporting-1-600: hsl(354, 85%, 44%);
  --plt-supporting-1-700: hsl(352, 90%, 35%);
  --plt-supporting-1-800: hsl(350, 94%, 28%);
  --plt-supporting-1-900: hsl(348, 94%, 20%);

  --plt-supporting-2-000: hsl(49, 100%, 96%);
  --plt-supporting-2-100: hsl(48, 100%, 88%);
  --plt-supporting-2-200: hsl(48, 95%, 76%);
  --plt-supporting-2-300: hsl(48, 94%, 68%);
  --plt-supporting-2-400: hsl(44, 92%, 63%);
  --plt-supporting-2-500: hsl(42, 87%, 55%);
  --plt-supporting-2-600: hsl(36, 77%, 49%);
  --plt-supporting-2-700: hsl(29, 80%, 44%);
  --plt-supporting-2-800: hsl(22, 82%, 39%);
  --plt-supporting-2-900: hsl(15, 86%, 30%);

  --plt-supporting-3-000: hsl(125, 65%, 93%);
  --plt-supporting-3-100: hsl(127, 65%, 85%);
  --plt-supporting-3-200: hsl(124, 63%, 74%);
  --plt-supporting-3-300: hsl(123, 53%, 55%);
  --plt-supporting-3-400: hsl(123, 57%, 45%);
  --plt-supporting-3-500: hsl(122, 73%, 35%);
  --plt-supporting-3-600: hsl(122, 80%, 29%);
  --plt-supporting-3-700: hsl(125, 79%, 26%);
  --plt-supporting-3-800: hsl(125, 86%, 20%);
  --plt-supporting-3-900: hsl(125, 97%, 14%);
}
