.button_primary_neutral,
.button_sm_primary_neutral,
.button_primary_primary,
.button_sm_primary_primary,
.button_primary_danger,
.button_sm_primary_danger,
.button_secondary_neutral,
.button_sm_secondary_neutral,
.button_secondary_primary,
.button_sm_secondary_primary,
.button_secondary_danger,
.button_sm_secondary_danger,
.button_tertiary_neutral,
.button_sm_tertiary_neutral,
.button_tertiary_primary,
.button_sm_tertiary_primary,
.button_tertiary_danger,
.button_sm_tertiary_danger,
.button_transparent_neutral,
.button_sm_transparent_neutral,
.button_transparent_primary,
.button_sm_transparent_primary,
.button_transparent_danger,
.button_sm_transparent_danger {
  display: inline-flex;
  flex: none;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: var(--fs-2);
  font-weight: 500;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  line-height: 1;
  border-radius: var(--br-2);
  cursor: pointer;
  text-decoration: none;
  padding: 0 16px;
  height: var(--input-height);
  outline: none;
  user-select: none;
  transition: background-color 0.2s;
  border: none;
  border-style: solid;
}

.button_primary_neutral::-moz-focus-inner,
.button_sm_primary_neutral::-moz-focus-inner,
.button_primary_primary::-moz-focus-inner,
.button_sm_primary_primary::-moz-focus-inner,
.button_primary_danger::-moz-focus-inner,
.button_sm_primary_danger::-moz-focus-inner,
.button_secondary_neutral::-moz-focus-inner,
.button_sm_secondary_neutral::-moz-focus-inner,
.button_secondary_primary::-moz-focus-inner,
.button_sm_secondary_primary::-moz-focus-inner,
.button_secondary_danger::-moz-focus-inner,
.button_sm_secondary_danger::-moz-focus-inner,
.button_tertiary_neutral::-moz-focus-inner,
.button_sm_tertiary_neutral::-moz-focus-inner,
.button_tertiary_primary::-moz-focus-inner,
.button_sm_tertiary_primary::-moz-focus-inner,
.button_tertiary_danger::-moz-focus-inner,
.button_sm_tertiary_danger::-moz-focus-inner,
.button_transparent_neutral::-moz-focus-inner,
.button_sm_transparent_neutral::-moz-focus-inner,
.button_transparent_primary::-moz-focus-inner,
.button_sm_transparent_primary::-moz-focus-inner,
.button_transparent_danger::-moz-focus-inner,
.button_sm_transparent_danger::-moz-focus-inner {
  border: 0;
}

.button_primary_neutral:disabled,
.button_sm_primary_neutral:disabled,
.button_primary_primary:disabled,
.button_sm_primary_primary:disabled,
.button_primary_danger:disabled,
.button_sm_primary_danger:disabled,
.button_secondary_neutral:disabled,
.button_sm_secondary_neutral:disabled,
.button_secondary_primary:disabled,
.button_sm_secondary_primary:disabled,
.button_secondary_danger:disabled,
.button_sm_secondary_danger:disabled,
.button_tertiary_neutral:disabled,
.button_sm_tertiary_neutral:disabled,
.button_tertiary_primary:disabled,
.button_sm_tertiary_primary:disabled,
.button_tertiary_danger:disabled,
.button_sm_tertiary_danger:disabled,
.button_transparent_neutral:disabled,
.button_sm_transparent_neutral:disabled,
.button_transparent_primary:disabled,
.button_sm_transparent_primary:disabled,
.button_transparent_danger:disabled,
.button_sm_transparent_danger:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button_primary_neutral {
  color: white;
  background-color: var(--plt-neutral-800);
  border-width: 1px;
  border-color: var(--plt-neutral-900);
}

.button_primary_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-700);
}

.button_primary_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-900);
}

.button_primary_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_primary_neutral_icon {
  fill: white;
  width: 13px;
  height: 13px;
}

.button_primary_neutral_icon_prepend {
  fill: white;
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_primary_neutral_icon_append {
  fill: white;
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_primary_neutral {
  color: white;
  background-color: var(--plt-neutral-800);
  border-width: 1px;
  border-color: var(--plt-neutral-900);
}

.button_sm_primary_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-700);
}

.button_sm_primary_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-900);
}

.button_sm_primary_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_sm_primary_neutral_icon {
  fill: white;
  width: 13px;
  height: 13px;
}

.button_sm_primary_neutral_icon_prepend {
  fill: white;
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_primary_neutral_icon_append {
  fill: white;
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_primary_primary {
  color: white;
  background-color: var(--plt-primary-500);
  border-width: 1px;
  border-color: var(--plt-primary-600);
}

.button_primary_primary:not(:disabled):hover {
  background-color: var(--plt-primary-400);
}

.button_primary_primary:not(:disabled):active {
  background-color: var(--plt-primary-700);
}

.button_primary_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-primary-200);
}

.button_primary_primary_icon {
  fill: white;
  width: 13px;
  height: 13px;
}

.button_primary_primary_icon_prepend {
  fill: white;
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_primary_primary_icon_append {
  fill: white;
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_primary_primary {
  color: white;
  background-color: var(--plt-primary-500);
  border-width: 1px;
  border-color: var(--plt-primary-600);
}

.button_sm_primary_primary:not(:disabled):hover {
  background-color: var(--plt-primary-400);
}

.button_sm_primary_primary:not(:disabled):active {
  background-color: var(--plt-primary-700);
}

.button_sm_primary_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-primary-200);
}

.button_sm_primary_primary_icon {
  fill: white;
  width: 13px;
  height: 13px;
}

.button_sm_primary_primary_icon_prepend {
  fill: white;
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_primary_primary_icon_append {
  fill: white;
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_primary_danger {
  color: white;
  background-color: var(--plt-danger-500);
  border-width: 1px;
  border-color: var(--plt-danger-600);
}

.button_primary_danger:not(:disabled):hover {
  background-color: var(--plt-danger-400);
}

.button_primary_danger:not(:disabled):active {
  background-color: var(--plt-danger-700);
}

.button_primary_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-danger-200);
}

.button_primary_danger_icon {
  fill: white;
  width: 13px;
  height: 13px;
}

.button_primary_danger_icon_prepend {
  fill: white;
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_primary_danger_icon_append {
  fill: white;
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_primary_danger {
  color: white;
  background-color: var(--plt-danger-500);
  border-width: 1px;
  border-color: var(--plt-danger-600);
}

.button_sm_primary_danger:not(:disabled):hover {
  background-color: var(--plt-danger-400);
}

.button_sm_primary_danger:not(:disabled):active {
  background-color: var(--plt-danger-700);
}

.button_sm_primary_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-danger-200);
}

.button_sm_primary_danger_icon {
  fill: white;
  width: 13px;
  height: 13px;
}

.button_sm_primary_danger_icon_prepend {
  fill: white;
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_primary_danger_icon_append {
  fill: white;
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_secondary_neutral {
  color: var(--plt-neutral-700);
  background-color: white;
  border-width: 2px;
  border-color: var(--plt-neutral-300);
}

.button_secondary_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-100);
}

.button_secondary_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-200);
}

.button_secondary_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_secondary_neutral_icon {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
}

.button_secondary_neutral_icon_prepend {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_secondary_neutral_icon_append {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_secondary_neutral {
  color: var(--plt-neutral-700);
  background-color: white;
  border-width: 2px;
  border-color: var(--plt-neutral-300);
}

.button_sm_secondary_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-100);
}

.button_sm_secondary_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-200);
}

.button_sm_secondary_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_sm_secondary_neutral_icon {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
}

.button_sm_secondary_neutral_icon_prepend {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_secondary_neutral_icon_append {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_secondary_primary {
  color: var(--plt-primary-500);
  background-color: white;
  border-width: 2px;
  border-color: var(--plt-primary-300);
}

.button_secondary_primary:not(:disabled):hover {
  background-color: var(--plt-primary-000);
}

.button_secondary_primary:not(:disabled):active {
  background-color: var(--plt-primary-100);
}

.button_secondary_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-primary-200);
}

.button_secondary_primary_icon {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
}

.button_secondary_primary_icon_prepend {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_secondary_primary_icon_append {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_secondary_primary {
  color: var(--plt-primary-500);
  background-color: white;
  border-width: 2px;
  border-color: var(--plt-primary-300);
}

.button_sm_secondary_primary:not(:disabled):hover {
  background-color: var(--plt-primary-000);
}

.button_sm_secondary_primary:not(:disabled):active {
  background-color: var(--plt-primary-100);
}

.button_sm_secondary_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-primary-200);
}

.button_sm_secondary_primary_icon {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
}

.button_sm_secondary_primary_icon_prepend {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_secondary_primary_icon_append {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_secondary_danger {
  color: var(--plt-danger-500);
  background-color: white;
  border-width: 2px;
  border-color: var(--plt-danger-300);
}

.button_secondary_danger:not(:disabled):hover {
  background-color: var(--plt-danger-000);
}

.button_secondary_danger:not(:disabled):active {
  background-color: var(--plt-danger-100);
}

.button_secondary_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-danger-200);
}

.button_secondary_danger_icon {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
}

.button_secondary_danger_icon_prepend {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_secondary_danger_icon_append {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_secondary_danger {
  color: var(--plt-danger-500);
  background-color: white;
  border-width: 2px;
  border-color: var(--plt-danger-300);
}

.button_sm_secondary_danger:not(:disabled):hover {
  background-color: var(--plt-danger-000);
}

.button_sm_secondary_danger:not(:disabled):active {
  background-color: var(--plt-danger-100);
}

.button_sm_secondary_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-danger-200);
}

.button_sm_secondary_danger_icon {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
}

.button_sm_secondary_danger_icon_prepend {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_secondary_danger_icon_append {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_tertiary_neutral {
  border-width: 3px;
  color: var(--plt-neutral-700);
  background-color: white;
  border-color: var(--plt-neutral-200);
  border-radius: 6px;
}

.button_tertiary_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-100);
}

.button_tertiary_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-200);
}

.button_tertiary_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_tertiary_neutral_icon {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
}

.button_tertiary_neutral_icon_prepend {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_tertiary_neutral_icon_append {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_tertiary_neutral {
  border-width: 3px;
  color: var(--plt-neutral-700);
  background-color: white;
  border-color: var(--plt-neutral-200);
  border-radius: 6px;
}

.button_sm_tertiary_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-100);
}

.button_sm_tertiary_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-200);
}

.button_sm_tertiary_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_sm_tertiary_neutral_icon {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
}

.button_sm_tertiary_neutral_icon_prepend {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_tertiary_neutral_icon_append {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_tertiary_primary {
  border-width: 3px;
  color: var(--plt-primary-500);
  background-color: white;
  border-color: var(--plt-neutral-200);
  border-radius: 6px;
}

.button_tertiary_primary:not(:disabled):hover {
  background-color: var(--plt-primary-000);
}

.button_tertiary_primary:not(:disabled):active {
  background-color: var(--plt-primary-100);
}

.button_tertiary_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_tertiary_primary_icon {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
}

.button_tertiary_primary_icon_prepend {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_tertiary_primary_icon_append {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_tertiary_primary {
  border-width: 3px;
  color: var(--plt-primary-500);
  background-color: white;
  border-color: var(--plt-neutral-200);
  border-radius: 6px;
}

.button_sm_tertiary_primary:not(:disabled):hover {
  background-color: var(--plt-primary-000);
}

.button_sm_tertiary_primary:not(:disabled):active {
  background-color: var(--plt-primary-100);
}

.button_sm_tertiary_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_sm_tertiary_primary_icon {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
}

.button_sm_tertiary_primary_icon_prepend {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_tertiary_primary_icon_append {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_tertiary_danger {
  border-width: 3px;
  color: var(--plt-danger-500);
  background-color: white;
  border-color: var(--plt-neutral-200);
  border-radius: 6px;
}

.button_tertiary_danger:not(:disabled):hover {
  background-color: var(--plt-danger-000);
}

.button_tertiary_danger:not(:disabled):active {
  background-color: var(--plt-danger-100);
}

.button_tertiary_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_tertiary_danger_icon {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
}

.button_tertiary_danger_icon_prepend {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_tertiary_danger_icon_append {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_tertiary_danger {
  border-width: 3px;
  color: var(--plt-danger-500);
  background-color: white;
  border-color: var(--plt-neutral-200);
  border-radius: 6px;
}

.button_sm_tertiary_danger:not(:disabled):hover {
  background-color: var(--plt-danger-000);
}

.button_sm_tertiary_danger:not(:disabled):active {
  background-color: var(--plt-danger-100);
}

.button_sm_tertiary_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_sm_tertiary_danger_icon {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
}

.button_sm_tertiary_danger_icon_prepend {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_tertiary_danger_icon_append {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_transparent_neutral {
  border-width: 3px;
  color: var(--plt-neutral-700);
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

.button_transparent_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-100);
}

.button_transparent_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-200);
}

.button_transparent_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_transparent_neutral_icon {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
}

.button_transparent_neutral_icon_prepend {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_transparent_neutral_icon_append {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_transparent_neutral {
  border-width: 3px;
  color: var(--plt-neutral-700);
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

.button_sm_transparent_neutral:not(:disabled):hover {
  background-color: var(--plt-neutral-100);
}

.button_sm_transparent_neutral:not(:disabled):active {
  background-color: var(--plt-neutral-200);
}

.button_sm_transparent_neutral:focus {
  box-shadow: 0 0 0 2px var(--plt-neutral-200);
}

.button_sm_transparent_neutral_icon {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
}

.button_sm_transparent_neutral_icon_prepend {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_transparent_neutral_icon_append {
  fill: var(--plt-neutral-700);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_transparent_primary {
  border-width: 3px;
  color: var(--plt-primary-500);
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

.button_transparent_primary:not(:disabled):hover {
  background-color: var(--plt-primary-000);
}

.button_transparent_primary:not(:disabled):active {
  background-color: var(--plt-primary-100);
}

.button_transparent_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-primary-200);
}

.button_transparent_primary_icon {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
}

.button_transparent_primary_icon_prepend {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_transparent_primary_icon_append {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_transparent_primary {
  border-width: 3px;
  color: var(--plt-primary-500);
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

.button_sm_transparent_primary:not(:disabled):hover {
  background-color: var(--plt-primary-000);
}

.button_sm_transparent_primary:not(:disabled):active {
  background-color: var(--plt-primary-100);
}

.button_sm_transparent_primary:focus {
  box-shadow: 0 0 0 2px var(--plt-primary-200);
}

.button_sm_transparent_primary_icon {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
}

.button_sm_transparent_primary_icon_prepend {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_transparent_primary_icon_append {
  fill: var(--plt-primary-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_transparent_danger {
  border-width: 3px;
  color: var(--plt-danger-500);
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

.button_transparent_danger:not(:disabled):hover {
  background-color: var(--plt-danger-000);
}

.button_transparent_danger:not(:disabled):active {
  background-color: var(--plt-danger-100);
}

.button_transparent_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-danger-200);
}

.button_transparent_danger_icon {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
}

.button_transparent_danger_icon_prepend {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_transparent_danger_icon_append {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_transparent_danger {
  border-width: 3px;
  color: var(--plt-danger-500);
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

.button_sm_transparent_danger:not(:disabled):hover {
  background-color: var(--plt-danger-000);
}

.button_sm_transparent_danger:not(:disabled):active {
  background-color: var(--plt-danger-100);
}

.button_sm_transparent_danger:focus {
  box-shadow: 0 0 0 2px var(--plt-danger-200);
}

.button_sm_transparent_danger_icon {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
}

.button_sm_transparent_danger_icon_prepend {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-left: -1px;
  margin-right: var(--sp-2);
}

.button_sm_transparent_danger_icon_append {
  fill: var(--plt-danger-500);
  width: 13px;
  height: 13px;
  margin-right: -1px;
  margin-left: var(--sp-2);
}

.button_sm_primary_neutral,
.button_sm_primary_primary,
.button_sm_primary_danger,
.button_sm_secondary_neutral,
.button_sm_secondary_primary,
.button_sm_secondary_danger,
.button_sm_tertiary_neutral,
.button_sm_tertiary_primary,
.button_sm_tertiary_danger,
.button_sm_transparent_neutral,
.button_sm_transparent_primary,
.button_sm_transparent_danger {
  padding: 0 13px;
  height: var(--input-sm-height);
  font-size: var(--fs-1);
}
